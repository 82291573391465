/* 7 Blocks/Widgets
----------------------------------------------------------------------------- */
/* Logo, Site Slogan
---------------------------------- */
.header.centered #logo img,
.header.centered #logo-mobile img {
	margin: 0 auto;
}
#logo-footer {
	margin-bottom: 20px;
}
.site-slogan {
	color: @gray;
	font-size: 11px;
	padding: 3px 0 0;
	font-weight: 500;
	letter-spacing: 0.5px;
	.opacity(1);
	.transition(all 0.3s ease-in-out);
}
.offcanvas {
	.logo {
		margin: 20px 0 10px;
		img {
			margin: 0 auto;
		}
	}
 	.site-slogan {
		text-align: center;
		margin-bottom: 20px;
		margin-top: -10px;
	}
}
.transparent-header .header:not(.dark) .site-slogan {
	color: #000000;
}
.header.dark .site-slogan { 
	color: @gray-lighter;
	text-shadow: 1px 1px rgba(0, 0, 0, 0.40);
}
.transparent-header .header.dark .site-slogan {
	color: #f1f1f1;
}
/* Medium devices (tablets, phones) */
@media (min-width: 992px) and (max-width: 1199px) { 
	.site-slogan {
		font-size: 11px;
	}
}
/* Small devices (tablets, phones less than 991px) */
@media (max-width: 991px) { 
	header:not(.full-width) .logo img {
		margin: 0 auto;
	}
	body.full-page header .logo img {
		margin: 0;
	}
	.site-slogan {
		text-align: center;
	}
	body.full-page .site-slogan {
		text-align: left;
	}
}
/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) { 
	.navbar-header #logo img,
	.navbar-header #logo-mobile img { 
		max-height: 35px;
		margin-left: 15px;
	}
}
/* Small devices (phones less than 480px) */
@media (max-width: 480px) { 
	header:not(.full-width) .header-dropdown-buttons + .logo img { 
		margin: 0;
	}
	.header-dropdown-buttons ~ .site-slogan {
		text-align: left;
	}
}
/* Social icons block
---------------------------------- */
.social-links {
	list-style: none;
	font-size: 0;
	margin-top: 20px;
	margin-bottom: 20px;
	padding-left: 0;
	
	li {
		display: inline-block;
		margin: 0 2px 2px 0;

		a {
			width: 40px;
			height: 40px;
			display: block;
			text-align: center;
			font-size: 18px;
			border: 1px solid #e7e7e7;
			color: @gray-lighter;
			.border-radius(4px);

			i {
				line-height: 40px;
			}

			&:hover {
				background-color: transparent;
				border-color: @base-color-hover;
				color: @base-color-hover;
			}
		}
	}
}
.social-links.animated-effect-1 li {
	a {
		.transition(color 0.4s);
		position: relative;
		z-index: 1;
	}
	a:hover {
		color: #ffffff!important;
		.transition(color 0.3s);
	}	
	a:after {
		pointer-events: none;
		position: absolute;
		width: 100%;
		height: 100%;
		.border-radius(50%);
		content: '';
		top: 0px;
		left: 0px;
		padding: 0px;
		z-index: -1;
		background-color: @base-color;
		-webkit-transition: -webkit-transform 0.4s, opacity 0.4s, background-color 0.4s;
		-moz-transition: -moz-transform 0.4s, opacity 0.4s, background-color 0.4s;
		transition: transform 0.4s, opacity 0.4s, background-color 0.4s;
		.scale(1.4);
		.opacity(0);
	}
	a:hover:after {
		.scale(1);
		opacity: 1;
		-webkit-transition: -webkit-transform 0s, opacity 0.3s, background-color 0.3s;
		-moz-transition: -moz-transform 0s, opacity 0.3s, background-color 0.3s;
		transition: transform 0s, opacity 0.3s, background-color 0.3s;
	}
}

.social-links.animated-effect-1:not(.circle) li {
	a:after {
		.border-radius(0%);
	}
}
.default.social-links li {
	a {
		background-color: @base-color;
		border: 1px solid @base-color-hover;
		color: #ffffff;
	}
	a:hover {
		background-color: @white;
		color: @gray;
	}	
}
.dark .social-links {
	li a {
		background-color: rgba(0, 0, 0, 0.2);
		border: 1px solid rgba(0, 0, 0, 0.1);
		color: #ffffff;
	}
}
.dark.social-links li {
	a {
		background-color: rgba(0, 0, 0, 0.6);
		border: 1px solid rgba(0, 0, 0, 0.1);
		color: #ffffff;
	}
	a:hover {
		background-color: rgba(0, 0, 0, 0.8);
	}	
}
.colored .social-links {
	li a {
		background-color: rgba(0, 0, 0, 0.2);
		border: 1px solid rgba(0, 0, 0, 0.01);
		color: #ffffff;
	}
}
.header-top .social-links {
	margin: 0;
	display: inline-block;
	margin-top: 2px;
}
.full-page .header .social-links {
	margin-top: 15px;
	margin-bottom: 0;
}
.footer-content .social-links {
	li {
		margin: 0 5px 5px 0;
		a {
			color: @gray;
			background-color: transparent;
			border-color: @gray;
			&:hover {
				color: #fff;
				background-color: @gray;
				border-color: @gray;
			}
		}
	}
}
.social-links.small li a {
	width: 30px;
	height: 30px;
	font-size: 14px;
	i {
		line-height: 29px;
	}
}
.social-links.large li a {
	width: 60px;
	height: 60px;
	font-size: 24px;
	i {
		line-height: 60px;
	}
}
.social-links.square li a,
.square {
	.border-radius(0);
}
.social-links.circle li a {
	.border-radius(100%);
}
.social-links.animated-effect-1 li.twitter a:hover {
	border-color: @twitter;
}
.social-links:not(.animated-effect-1) li.twitter a:hover,
.colored.social-links li.twitter a,
.social-links.animated-effect-1 li.twitter a:after,
.btn.twitter {
	background-color: @twitter;
	border-color: @twitter;
	color: #fff;
}
.social-links.animated-effect-1 li.skype a:hover {
	border-color: @skype;
}
.social-links:not(.animated-effect-1) li.skype a:hover,
.colored.social-links li.skype a,
.social-links.animated-effect-1 li.skype a:after,
.btn.skype {
	background-color: @skype;
	border-color: @skype;
	color: #fff;
}
.social-links.animated-effect-1 li.linkedin a:hover {
	border-color: @linkedin;
}
.social-links:not(.animated-effect-1) li.linkedin a:hover,
.colored.social-links li.linkedin a,
.social-links.animated-effect-1 li.linkedin a:after,
.btn.linkedin {
	background-color: @linkedin;
	border-color: @linkedin;
	color: #fff;
}
.social-links.animated-effect-1 li.googleplus a:hover {
	border-color: @googleplus;
}
.social-links:not(.animated-effect-1) li.googleplus a:hover,
.colored.social-links li.googleplus a,
.social-links.animated-effect-1 li.googleplus a:after,
.btn.googleplus {
	background-color: @googleplus;
	border-color: @googleplus;
	color: #fff;
}
.social-links.animated-effect-1 li.youtube a:hover {
	border-color: @youtube;
}
.social-links:not(.animated-effect-1) li.youtube a:hover,
.colored.social-links li.youtube a,
.social-links.animated-effect-1 li.youtube a:after,
.btn.youtube {
	background-color: @youtube;
	border-color: @youtube;
	color: #fff;
}
.social-links.animated-effect-1 li.flickr a:hover {
	border-color: @flickr;
}
.social-links:not(.animated-effect-1) li.flickr a:hover,
.colored.social-links li.flickr a,
.social-links.animated-effect-1 li.flickr a:after,
.btn.flickr {
	background-color: @flickr;
	border-color: @flickr;
	color: #fff;
}
.social-links.animated-effect-1 li.facebook a:hover {
	border-color: @facebook;
}
.social-links:not(.animated-effect-1) li.facebook a:hover,
.colored.social-links li.facebook a,
.social-links.animated-effect-1 li.facebook a:after,
.btn.facebook {
	background-color: @facebook;
	border-color: @facebook;
	color: #fff;
}
.social-links.animated-effect-1 li.pinterest a:hover {
	border-color: @pinterest;
}
.social-links:not(.animated-effect-1) li.pinterest a:hover,
.colored.social-links li.pinterest a,
.social-links.animated-effect-1 li.pinterest a:after,
.btn.pinterest {
	background-color: @pinterest;
	border-color: @pinterest;
	color: #fff;
}
.social-links.animated-effect-1 li.instagram a:hover {
	border-color: @instagram;
}
.social-links:not(.animated-effect-1) li.instagram a:hover,
.colored.social-links li.instagram a,
.social-links.animated-effect-1 li.instagram a:after,
.btn.instagram {
	background-color: @instagram;
	border-color: @instagram;
	color: #fff;
}
.social-links.animated-effect-1 li.vimeo a:hover {
	border-color: @vimeo;
}
.social-links:not(.animated-effect-1) li.vimeo a:hover,
.colored.social-links li.vimeo a,
.social-links.animated-effect-1 li.vimeo a:after,
.btn.vimeo {
	background-color: @vimeo;
	border-color: @vimeo;
	color: #fff;
}
.social-links.animated-effect-1 li.tumblr a:hover {
	border-color: @tumblr;
}
.social-links:not(.animated-effect-1) li.tumblr a:hover,
.colored.social-links li.tumblr a,
.social-links.animated-effect-1 li.tumblr a:after,
.btn.tumblr {
	background-color: @tumblr;
	border-color: @tumblr;
	color: #fff;
}
.social-links.animated-effect-1 li.soundcloud a:hover {
	border-color: @soundcloud;
}
.social-links:not(.animated-effect-1) li.soundcloud a:hover,
.colored.social-links li.soundcloud a,
.social-links.animated-effect-1 li.soundcloud a:after,
.btn.soundcloud {
	background-color: @soundcloud;
	border-color: @soundcloud;
	color: #fff;
}
.social-links.animated-effect-1 li.foursquare a:hover {
	border-color: @foursquare;
}
.social-links:not(.animated-effect-1) li.foursquare a:hover,
.colored.social-links li.foursquare a,
.social-links.animated-effect-1 li.foursquare a:after,
.btn.foursquare {
	background-color: @foursquare;
	border-color: @foursquare;
	color: #fff;
}
.social-links.animated-effect-1 li.dribbble a:hover {
	border-color: @dribbble;
}
.social-links:not(.animated-effect-1) li.dribbble a:hover,
.colored.social-links li.dribbble a,
.social-links.animated-effect-1 li.dribbble a:after,
.btn.dribbble {
	background-color: @dribbble;
	border-color: @dribbble;
	color: #fff;
}
.social-links.animated-effect-1 li.xing a:hover{
	border-color: @xing;
}
.social-links:not(.animated-effect-1) li.xing a:hover,
.colored.social-links li.xing a,
.social-links.animated-effect-1 li.xing a:after,
.btn.xing {
	background-color: @xing;
	border-color: @xing;
	color: #fff;
}
.social-links.animated-effect-1 li.behance a:hover{
	border-color: @xing;
}
.social-links:not(.animated-effect-1) li.behance a:hover,
.colored.social-links li.behance a,
.social-links.animated-effect-1 li.behance a:after,
.btn.behance {
	background-color: @behance;
	border-color: @behance;
	color: #fff;
}
.social-links.animated-effect-1 li.vine a:hover{
	border-color: @xing;
}
.social-links:not(.animated-effect-1) li.vine a:hover,
.colored.social-links li.vine a,
.social-links.animated-effect-1 li.vine a:after,
.btn.vine {
	background-color: @vine;
	border-color: @vine;
	color: #fff;
}
.social-links.animated-effect-1 li.stumbleupon a:hover{
	border-color: @xing;
}
.social-links:not(.animated-effect-1) li.stumbleupon a:hover,
.colored.social-links li.stumbleupon a,
.social-links.animated-effect-1 li.stumbleupon a:after,
.btn.stumbleupon {
	background-color: @stumbleupon;
	border-color: @stumbleupon;
	color: #fff;
}
.social-links .dropdown>button {
	padding: 1px 15px 0px;
	font-size: 16px;
	color: @gray-light;
	text-align: center;
	min-width: 0;
	margin: 0;
	background-color: transparent;
	-moz-box-shadow: none !important;
	-o-box-shadow: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}
.social-links .dropdown>button i {
	width: 15px;
}
.header-top:not(.dark) .social-links .dropdown.open>button>i:before {
	color: @base-color;
}
.header-top.colored .social-links .dropdown>button>i:before,
.header-top.colored .social-links .dropdown.open>button>i:before {
	color: @white;
}
.header-top .social-links .dropdown.open>button>i:before {
	content: "\f00d";
}
.social-links .dropdown-menu {
	z-index: 103;
	padding: 5px;
	min-width: 270px;
	margin-top: 6px;
	font-size: 0;
	.box-shadow(none);
	background-color: #f2f2f2;
	border: 1px solid #D0D0D0;
	border-top: none;	
}
.social-links .dropdown-menu li a,
.dark .social-links .dropdown-menu li a i,
.colored .social-links .dropdown-menu li a i {
	padding: 0;
	line-height: 30px;
	color: #ffffff;
}
.social-links .dropdown-menu li a i {
	padding-left: 0;
}
.social-links .dropdown-menu li a:hover i {
	color: #ffffff;
}
/*Header Dropdowns (search, cart etc)
---------------------------------- */
/*Header Dropdowns*/
.transparent-header .header-dropdown-buttons { 
	.btn-group {
		> .btn {
			background-color: transparent;
			border: 1px solid #999999;
			color: #000000;
		}
	}
}
.transparent-header.gradient-background-header .header-dropdown-buttons { 
	.btn-group {
		> .btn {
			border: 1px solid #333333;
		}
	}
}
.header-dropdown-buttons { 
	.btn-group {
		&:first-child {
			margin-right: 5px;
		}
		> .btn {
			.border-radius(100%);
			width: 40px;
			height: 40px;
			padding: 0;
			line-height: 38px;
			text-align: center;
			background-color: #f2f2f2;
			border: 1px solid #e9e9e9;
			.transition(all 0.2s ease-in-out);
			margin: 0;
			&:hover {
				background-color: @base-color;
				color: #fff;
				border-color: @base-color-hover;
			}
		}
		.dropdown-menu {
			z-index: 103;
			padding: 20px;
			min-width: 280px;
			margin-top: 18px;
			top: 100%;
			border: none;
			.border-radius(0 0 5px 5px);			
		}
	}
	.btn-group.open { 
		> .btn { 
			background-color: @base-color;
			color: #fff;
			border-color: @base-color-hover;
		}
	}
}
.header.centered .header-dropdown-buttons { 
	.btn-group {
		.dropdown-menu {
			margin-top: 8px;
		}
	}
}
.dark,
.colored {
	.header-dropdown-buttons { 
		.btn-group {
			> .btn {
				background-color: rgba(0, 0, 0, 0.20);
				border: 1px solid rgba(0, 0, 0, 0.15);
				text-shadow: 1px 1px rgba(0, 0, 0, 0.70);
				color: #cdcdcd;
				&:hover {
					background-color: rgba(0, 0, 0, 0.30);
					border: 1px solid rgba(0, 0, 0, 0.25);
				}
			}
		}
		.btn-group.open { 
			> .btn { 
				background-color: rgba(0, 0, 0, 0.30);
				border: 1px solid rgba(0, 0, 0, 0.25);
			}
		}
	}
}

.btn-group.open .dropdown-animation {
	.animation-duration(0.3s);
	.animation-fill-mode(both);
	.animation-name(fadeIn);
}

/*Header Top Dropdowns*/
.header-top-dropdown {
	.dropdown-menu {
		z-index: 103;
		padding: 20px;
		min-width: 280px;
		margin-top: 6px;
		border: 1px solid #D0D0D0;
		border-top: none;
		background: #f2f2f2;
		font-weight: 400;
		.border-radius(0 0 5px 5px);
	}
	.login-form {
		ul {
			padding-left: 0;
			list-style-position: inside;
			margin: 15px 0 10px;
		}
		.social-links {
			padding-left: 20px;
			margin: 0;
		}
		label {
			font-weight: 400;
		}
	}
}
.dark.header-top {
	.dropdown-menu {
		border: 1px solid @dark-header-top-border;
		border-top: none;
		background: @dark-header-top;
	}
}
.colored.header-top {
	.dropdown-menu {
		border: 1px solid @base-color;
		border-top: none;
		background: @base-color;
	}
}
.search-box .form-group {
	margin-bottom: 0;
}

/*Button Groups*/
.header-top .btn-group {
	margin: 3px 0 2px;
	.btn {
		margin: 0;
		text-decoration: none;
	}
}

/* Extra small devices (phones, less than 480px) */
@media (max-width: 480px) {
	.header-top-dropdown .btn-group {
		position: static;
	}
	.header-top-dropdown .dropdown-menu {
		right: 15px!important;
		margin-top: 5px;
		width: 100%;
	}
}

/*Dark Dropdowns
---------------------------------- */
.dark.btn-group {
	.dropdown-menu {
		background-color: #333333;
		border: 1px solid #424242;
		border-bottom: none;
	}
	.dropdown-menu .divider {
		background-color: #505050;
	}
	.dropdown-menu > li > a,
	.mega-menu .menu > li > a {
		color: #cccccc;
		border-bottom: 1px solid #424242;
		text-shadow: 1px 1px rgba(0, 0, 0, 0.70);
	}
	.dropdown-menu > li > a:hover, 
	.dropdown-menu > li > a:focus, .nav .open > a, 
	.nav .open > a:hover, 
	.nav .open > a:focus, 
	.dropdown-menu > .active > a, 
	.dropdown-menu > .active > a:hover, 
	.dropdown-menu > .active > a:focus {
		background-color: rgba(0, 0, 0, 0.2);
		color: #ffffff;
		border-color: #424242;
	}
	.dropdown .dropdown > a:before {
		color: #cccccc;
		top: 13px;
	}
	.dropdown .dropdown.open > a:before {
		color: #ffffff;
	}
}
/* Login/Register Forms
---------------------------------- */
.form-block {
	.border-radius(10px);
	width: 630px;
}

.form-block .social-links { 
	display: inline-block;
	margin: 0 0 0 5px;
}
.form-block ul {
	padding-left: 0;
	list-style-position: inside;
}
.light-gray-bg.form-block h2,
.light-gray-bg.form-block a,
.light-gray-bg.form-block a:hover,
.light-gray-bg.form-block {
	color: @gray;
}
/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) { 
	.form-block {
		width: 90%;
	}
}
/* Latest tweets block
---------------------------------- */
ul.tweets {
	list-style: none;
	padding: 0;
	li {
		margin-bottom: 20px;
		position: relative;
		padding-left: 35px;
		i {
			position: absolute;
			top: 4px;
			left: 0;
			width: 25px;
			height: 25px;
			text-align: center;
			color: @gray;
		}
		p {
			margin-bottom: 5px;
		}
		span {
			font-size: 12px;
			color: @gray;
		}
	}
}
/* Media 
---------------------------------- */
.media {
  margin-top: 0;
  margin-bottom: 25px;
}
.media img {
	max-width: none;
}
.footer-content .media img,
.block .media img  {
	width: 60px;
}
.media-heading {
  margin-bottom: 0;
}
h6.media-heading {
  margin-bottom: 5px;
}
.media:hover .icon {
	background-color: transparent;
	color: @base-color;
	border: 1px solid @base-color;
}
.dark-bg .media:hover .icon {
	border-color: #cdcdcd;
	color: #cdcdcd;
}
.media:hover .icon:after {
	content: "";
	border-color: transparent;
}

/* Testimonials
---------------------------------- */
.testimonial {
	padding: 20px 0;
}
.testimonial blockquote {
	margin: 0;
}
.testimonial .separator {
	margin: 15px auto 5px;
}
.testimonial-image {
	width: 90px;
	margin: 0 auto;
}
.testimonial-info-1 {
	font-size: 12px;
}
.testimonial-info-2 {
	font-size: 12px;
	font-weight: 400;
}
.testimonial h3 {
	margin-bottom: 0;
	margin-top: 15px;
}
.testimonial p {
	margin-bottom: 15px;
}
/* Clients
---------------------------------- */
.clients-container {
	text-align: center;
}
.clients {
	margin: 10px auto;
}
.clients .client-image {
	text-align: center;
	width: 80px;
	height: 80px;
	margin: 5px;
	display: inline-block;
}
.clients img {	
	margin: 0px auto;
}
/* Full Width Content
---------------------------------- */
.full-width-section {
	position: relative;
}
.full-text-container {
	position: absolute;
	top: 30%;
	padding: 20px 40px;
	z-index: 2;
}
.full-image-overlay {
	display: none;
	position: absolute;
	width: 100%;
	top: 50%;
	padding: 0 10%;
	margin-top: -133px;
	color: #ffffff;
	z-index: 1;
	.transition(all 0.3s ease-in-out);
	.transition-delay(0s);
}
.full-image-overlay h3 {
	color: #ffffff;
}
.to-right-block {
	margin-left: auto;
	display: block;
}
/* Large devices (Large desktops 1200px and up) */
@media (min-width: 1200px) { 
	.full-width-section {
		position: relative;
		display: table;
		table-layout: fixed;
		width: 100%;
	}
	.full-width-section.no-image {
		min-height: 400px;
	}
	.full-image-container {
		width: 50%;
		display: table-cell;
		position: relative;
		overflow: hidden;
	}
	.full-image-overlay {
		display: block;
		.opacity(0);
	}
	.full-image-container:before {
		content:'';
		position:absolute;
		top: 5%;
		right: 0%;
		width: 50%;
		height: 90%;
		background-color: rgba(55, 55, 55, 0.6);
		.opacity(0);
		.transition(all 0.3s ease-in-out);
		.transition-delay(0.3s);
	}
	.full-text-container + .full-image-container:before { 
		right: auto;
		left: 0;
	}
	.full-width-section:hover .full-image-container:before,
	.full-width-section .full-image-container.hovered:before {
		width: 90%;
		right: 5%;
		.opacity(1);
		.transition-delay(0s);
	}
	.full-width-section:hover .full-text-container + .full-image-container:before,
	.full-width-section .full-text-container + .full-image-container.hovered:before {
		right: auto;
		left: 5%;
	}
	.full-width-section.hover-disabled:hover .full-image-container:before,
	.full-width-section.hover-disabled .full-image-container.hovered:before {
		.opacity(0);
	}
	.full-width-section:hover .full-image-overlay,
	.full-width-section .hovered .full-image-overlay  {
		.opacity(1);
		.transition-delay(0.3s);
	}
	.full-text-container {
		display: table-cell;
		vertical-align: middle;
		width: 68%;
		padding: 0 50px;
		position: static;
	}
	.full-text-container * {
		max-width: 100%;
	}
	.full-text-container.left {
		text-align: right;
	}
	.full-text-container.left * {
		margin-left: auto;
	}
	.full-text-container p {
		margin-bottom: 10px;
	}
}
/* Large and Medium devices (desktops, tablets less than 1199px) */
@media (max-width: 1199px) { 
	.no-image .full-text-container {
		position: static;
		display: block;
	}
	.full-width-section:not(.no-image) .full-text-container.dark-bg {
		background-color: rgba(0,0,0,0.8);
		width: 100%;
	}
	.full-width-section:not(.no-image) .full-text-container.light-gray-bg {
		background-color: rgba(255,255,255,0.8);
		color: #000000;
		border-top: none;
		border-bottom: none;
		width: 100%;
	}
	.full-width-section:not(.no-image) .full-text-container.white-bg {
		background-color: rgba(255, 255, 255, 0.8);
		color: #000000;
		border-top: none;
		border-bottom: none;
		width: 100%;
	}
	.full-width-section:not(.no-image) .full-text-container.default-bg {
		background-color: rgba(9, 175, 223, 0.6);
		width: 100%;
	}
	.full-width-section .to-right-block,
	.full-width-section img {
		margin: 0 auto;
	}
}

/* Medium devices (desktops, tablets less than 991px) */
@media (max-width: 991px) { 
	.full-width-section:not(.no-image) .full-text-container {
		bottom: 0;
		height: auto;
		padding: 20px;
	}
	.full-text-container h1 {
		font-size: 28px;
	}
}

/* Extra small devices (phones, less than 480px) */
@media (max-width: 480px) {
	.full-text-container {
		position: static;
		padding: 20px!important;
	}
}
/* Call to action
---------------------------------- */
.call-to-action h1,
.call-to-action a {
	margin: 0;
}
.call-to-action p {
	margin-bottom: 10px;
}
/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) { 
	.call-to-action .text-right {
		text-align: left;
	}
}
/* Counters
---------------------------------- */
.counter {
	display: block;
	font-size: 32px;
	line-height: 1;
}

/* Pricing tables
---------------------------------- */
.pricing-tables {
	margin: 60px 0 0;
}
.plan {
	text-align: center;
	margin-bottom: 40px !important;
}
.plan.round-corners .header {
	.border-radius(25px 25px 0 0);
}
.plan.round-corners ul li:last-child,
.plan.round-corners ul {
	.border-radius(0 0 25px 25px);
}
.plan .header {
	padding: 0;
	margin: 0 -1px;
	border: none;
}
.plan .header h3 {
	font-size: 36px;
	margin: 0;
	line-height: 36px;
	font-weight: 300;
	padding: 15px 0 10px;
	border: 1px solid rgba(0,0,0,0.05);
	border-top: none;
}
.plan .header .price {
	font-size: 28px;
	padding: 8px 0 10px;
	font-weight: 300;
	background-color: rgba(255,255,255,0.1);
	border: 1px solid rgba(0,0,0,0.1);
	border-top: 1px solid rgba(255,255,255,0.15);
	line-height: 1;
	color: #ffffff;
}
.plan ul {
	list-style: none;
	margin: 0;
	padding: 0;
	font-size: 14px;
	list-style: none;
}
.plan ul li {
	padding: 12px 0px;
	font-size: 13px;
	border-bottom: 1px solid #f3f3f3;
	border-top: 1px solid #ffffff;
	color: @gray-dark;
}
.best-value.plan ul li {
	padding: 15px 0;
}
.plan ul li a.pt-popover {
	color: #000;
	border-bottom: 1px dashed #000;
	position: relative;
}
.plan ul li a.pt-popover:hover {
	text-decoration: none;
}
.plan ul li a.pt-popover:after {
	position: absolute;
	top: 0px;
	left: -15px;
	font-family: "FontAwesome";
	content: "\f059";
	color: #ddd;
	font-size: 12px;
}
.popover {
	width: 250px;
	font-size: 13px;
	text-align: center;
	border: none;
}
.popover-title {
	background-color: @base-color;
	color: #ffffff;
	text-shadow: none;
	font-size: 13px;
}
.popover-content {
	font-size: 12px;
}
.circle-head .plan .header {
	padding: 0;
	border: none;
	width: 160px;
	height: 160px;
	border-radius: 100%;
	margin: 20px auto;
	padding: 10px 0;
}
.circle-head .plan .header h3 {
	font-size: 26px;
	border: none;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	padding-top: 27px;
}
.circle-head .plan .header .price {
	height: 80px;
	border: none;
	padding-top: 15px;
	font-size: 22px;
	border-top: 1px solid rgba(255, 255, 255, 0.15);
}
.stripped .plan ul {
	-webkit-box-shadow: 0px -15px 32px 0px #cacaca inset;
	-moz-box-shadow: 0px -15px 32px 0px #cacaca inset;
	box-shadow: 0px -15px 32px 0px #cacaca inset;
	background: #fdfdfd;
}
.stripped .plan ul li {
	border: 1px solid #ececec;
	border-top: none;
	color: #666;
}
.stripped .plan ul li:nth-child(even) {
	-webkit-box-shadow: 0px 0px 40px #e4e4e4 inset;
	-moz-box-shadow: 0px 0px 40px #e4e4e4 inset;
	box-shadow: 0px 0px 40px #e4e4e4 inset;
	background: #ffffff;
	border-right: 1px solid #e2e2e2;
	border-bottom: 1px solid #e2e2e2;
}

/* Small devices (tablets, 768px and up) */
@media (min-width:768px) {
	.best-value.plan {
		top: -30px;
		position: relative;
		z-index: 2;
	}
}

/* Google maps
---------------------------------- */
#map-canvas {
	height: 450px;
}
#map-canvas.small {
	height: 347px;
}
#collapseMap {
	overflow: hidden;
	height: 0;
	.transition(height 0.3s ease-in-out);
}
#collapseMap.in { 
	height: 450px;
}
.footer-content #map-canvas {
	height: 300px;
}
#map-canvas img {
	max-width: inherit;
}
/* Tags cloud block
---------------------------------- */
.tags-cloud {
	font-size: 0;
}
.tag {
	display: inline-block;
	margin: 0 3px 3px 0;
}
.tag a {
	padding: 3px 10px;
	.border-radius(4px);
	color: #ffffff;
	display: block;
	font-size: 11px;
	background-color: @base-color;
	border: 1px solid @base-color-hover;
}
.tag a:hover {
	color: @base-color;
	background-color: @white;
	border-color: @base-color-hover;
	text-decoration: none;
}
.dark {
	.tag a {
	color: @gray;
	background-color: transparent;
	border: 1px solid @gray-dark;
	}
	.tag a:hover {
		color: @white;
		background-color: @gray;
		border-color: @gray;
		text-decoration: none;
	}
}
/* Credit Carts Block
---------------------------------- */
.icons-block i {
	display: inline-block;
	font-size: 30px;
	color: @gray-light;
}
/* Isotope filtering
---------------------------------- */
.isotope-container {
	display: none;
}
.isotope-container-fitrows {
	display: none;
}
/* Small devices (tablets, phones less than 991px) */
@media (max-width:991px) {
	.isotope-item,
	.masonry-grid-item {
		max-width: 100%;
	}
}
/* Affix Menu
---------------------------------- */
.sidebar.affix {
	position: static;
}

/* Show and affix the side nav when space allows it */
/* Medium devices (desktops, tablets, 992px and up) */
@media (min-width:992px) {
	.col-md-4 .sidebar.affix,
	.col-md-4 .sidebar.affix-bottom {
		width: 293px;
	}
	.col-md-3 .sidebar.affix,
	.col-md-3 .sidebar.affix-bottom {
		width: 213px;
	}
	.sidebar.affix {
		position: fixed;
		top: 20px;
	}
	.fixed-header-on .sidebar.affix {
		top: 65px;
	}
	.sidebar.affix-bottom {
		position: absolute;
	}
	.sidebar.affix-bottom .affix-menu,
	.sidebar.affix .affix-menu {
		margin-top: 0;
		margin-bottom: 0;
	}
	.affix-menu {
		padding-top: 20px;
	}
	h3.title ~ .affix-menu {
		padding-top: 0px;
	}
}

/* Large devices (Large desktops 1200px and up) */
@media (min-width:1200px) {
	.col-md-4 .sidebar.affix,
	.col-md-4 .sidebar.affix-bottom {
		width: 390px;
	}
	.col-md-3 .sidebar.affix,
	.col-md-3 .sidebar.affix-bottom {
		width: 263px;
	}
}
/* Scroll to top
---------------------------------- */
.scrollToTop {
	color: #fff;
	position: fixed;
	bottom: 5px;
	right: 5px;
	width: 50px;
	height: 50px;
	text-align: center;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 1005;
	display: none;
	-webkit-backface-visibility:hidden; 
	-webkit-transform: translateZ(0);	
	.transition(all 0.2s ease-in-out);

	i {
		line-height: 50px;
		font-size: 24px;
	}
}
.scrollToTop:hover {
	background-color: rgba(0, 0, 0, 0.6);
}
/* Small devices (tablets, phones less than 767px) */
@media (max-width:767px) {
	.scrollToTop {
		width: 40px;
		height: 40px;
		background-color: rgba(0, 0, 0, 0.4);

		i {
			line-height: 40px;
			font-size: 20px;
		}
	}
}
